import { Link } from "gatsby";
import React, { EventHandler, FormEvent, MouseEventHandler, useState } from "react";
import LoadingSpinner from "./LoadingSpinner";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const ContactForm = ({ id = null, containerClass = null }) => {
    const [canEditForm, setCanEditForm] = useState(true);
    const [showContactButton, setShowContactButton] = useState(true);
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [formName, setFormName] = useState("");
    const [formPhone, setFormPhone] = useState("");
    const [formEmail, setFormEmail] = useState("");
    const [formComments, setformComments] = useState("");
    const [formAcceptedToS, setFormAcceptedToS] = useState(false);
    const [formToken, setFormToken] = useState("");

    const [formErrors, setFormErrors] = useState([]);

    const loadingSpinner = <LoadingSpinner backgroundColor="whitesmoke" size={16} />;

    function validateForm() {
        const errors = [];
        if (formName.trim() == "") {
            errors.push("¡Debes introducir tu nombre!");
        }

        if (formEmail.trim() == "") {
            errors.push("¡Debes introducir tu correo electrónico!");
        } else {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(formEmail)) {
                errors.push("El correo electrónico introducido no es válido");
            }
        }

        if (!formAcceptedToS) {
            errors.push("Debes aceptar nuestros términos y condiciones");
        }

        return errors;
    }

    const { executeRecaptcha } = useGoogleReCaptcha();

    async function onContactButtonClick(e: FormEvent) {
        e.preventDefault();

        if (!executeRecaptcha) {
            return
        }

        setCanEditForm(false);

        const errors = validateForm();
        if (errors.length > 0) {
            setFormErrors(errors);
            setCanEditForm(true);
            return;
        }

        const result = await executeRecaptcha("homepage");
        setFormToken(result);

        const formData = JSON.stringify({
            nombre: formName,
            telefono: formPhone,
            correo: formEmail,
            comentarios: formComments,
            token: result,
            enviar: process.env.NODE_ENV !== "dev" ? "1" : null
        });

        //const contactFormUrl = "http://localhost:7071/api/contact-form";
        const contactFormUrl = "/api/contact-form";
        fetch(contactFormUrl, {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-type": "application/json",
            },
            body: formData,
            mode: "no-cors",
        })
            .then(res => {
                setShowContactButton(false);
                setShowMessage(true);
            })
            .then(data => setFormErrors([data]));
    };

    if (showMessage) {
        return <h3 className="font-bold text-green-500">¡Gracias! Tu solicitud de contacto se ha enviado. Nos pondremos en contacto contigo lo antes posible.</h3>
    }

    return (
        <form id={id} className={containerClass} onSubmit={onContactButtonClick}>
            <small className="lh-1 mb-6">¿Quieres saber más?, ¡nos ponemos en contacto contigo!</small>
            <hr className="border-gray-300 my-3" />

            {
                formErrors.length > 0 &&
                <div className="flex-1 flex-column">
                    {formErrors.map((error, i) => <p key={`form-error-${i}`} className="text-xs text-red-700">{error}</p>)}

                    <hr className="border-gray-300 my-3" />
                </div>
            }

            <div className="flex flex-wrap -mx-3 mb-3">

                <div className="w-full xl:w-1/2 mb-3 px-3">
                    <label htmlFor="contacto-nombre" className="block uppercase tracking-wide text-gray-700 mb-2">
                        Tu nombre*
                    </label>

                    <input id="contacto-nombre" value={formName} onChange={e => setFormName(e.target.value)}
                        required disabled={!canEditForm} type="text"
                        className="disabled:opacity-50 w-full shadow appearance-none rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring focus:ring-indigo-200" />
                </div>

                <div className="w-full xl:w-1/2 mb-3 md:mb-0 px-3">
                    <label htmlFor="contacto-telefono" className="block uppercase tracking-wide text-gray-700 mb-2">
                        Tú teléfono
                    </label>

                    <input id="contacto-telefono" disabled={!canEditForm} value={formPhone} onChange={e => setFormPhone(e.target.value)}
                        type="text"
                        className="disabled:opacity-50 w-full shadow appearance-none rounded py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring focus:ring-indigo-200" />
                </div>

            </div>

            <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full mb-3 md:mb-0 px-3">
                    <label htmlFor="contacto-correo-electronico" className="block uppercase tracking-wide text-gray-700 mb-2">
                        Tu correo electrónico*
                    </label>

                    <input id="contacto-correo-electronico" required disabled={!canEditForm} type="email"
                        value={formEmail} onChange={e => setFormEmail(e.target.value)}
                        className="disabled:opacity-50 shadow appearance-none w-full rounded py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring focus:ring-indigo-200" />
                </div>
            </div>

            <div className="flex flex-wrap -mx-3">
                <div className="w-full mb-3 md:mb-0 px-3">
                    <label htmlFor="contacto-comentario" className="w-full block uppercase tracking-wide text-gray-700 mb-2">
                        Comentarios
                    </label>

                    <span className="text-xs">Ejemplo: <em>Llamadme de lunes a viernes de 9h a 18h.</em></span>

                    <textarea id="contacto-comentario" disabled={!canEditForm}
                        value={formComments} onChange={e => setformComments(e.target.value)}
                        className="disabled:opacity-50 shadow appearance-none w-full rounded py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring focus:ring-indigo-200" />
                </div>
            </div>

            <div className="flex flex-wrap -mx-3">
                <div className="w-full mb-3 md:mb-0 px-3">
                    <span className="text-xs">Los campos marcados con un asterisco (*) son obligatorios.</span>
                </div>
            </div>

            {
                showContactButton &&
                <div className="my-3">
                    <hr className="border-gray-300 my-3" />

                    <label className="text-gray-700">
                        <input type="checkbox" disabled={!canEditForm} checked={formAcceptedToS} onChange={e => setFormAcceptedToS(e.target.checked)} required
                            className="rounded appearance-none checked:bg-indigo-400 border-outline-0 focus:ring focus:ring-indigo-200 hover:bg-indigo-100" />
                        <span className="ml-1">Acepto los <Link to="/aviso-legal/" target="_blank" title="Ir a Aviso legal">términos y condiciones</Link></span>
                    </label>

                    <input type="submit" disabled={!canEditForm} value="Contacta con nosotros"
                        className="hover:animate-shake disabled:opacity-50 mx-auto lg:mx-0 bg-white text-gray-800 font-bold rounded my-2 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out w-full" />
                </div>
            }

            {isLoading && loadingSpinner}

        </form >
    );

};

export default ContactForm;